// @flow

import React from 'react';
import Layout from '../components/Layout/Layout';
import ProjectLayout from '../components/ProjectLayout/ProjectLayout';
import MolasContent from '../components/ProjectsContent/MolasContent/MolasContent';

const MolasPage = ({location: {pathname}}) => {
  const description = () => (
    <div>
      Main visuals and posters for<br/>
      shopping center MOLAS.
      <br/><br/>
      Made in ALL CAPS agency.
    </div>
  );

  return (
    <ProjectLayout pathname={pathname} description={description()}>
      <MolasContent/>
    </ProjectLayout>
  );
};

export default MolasPage;
