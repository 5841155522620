import React from 'react';
import styles from './MolasContent.module.scss';
import { graphql, useStaticQuery } from 'gatsby';
import Image from '../../Image/Image';

const MolasContent = () => {
  const data = useStaticQuery(graphql`query MolasContent {
            allCloudinaryMedia(sort: {fields: public_id}, filter: {public_id: {regex: "/gallery/molas/"}}) {
              edges {
                node {
                  public_id
                  secure_url
                }
              }
            }
        }`);

  const urls = data.allCloudinaryMedia.edges.map((image) => image.node.secure_url);

  function renderImages(urls) {
    return urls.map((url, i) => (
      <Image url={url} key={`img-${i}`}/>
    ));
  }
  return (
    <div className={styles.molasContent}>
      {renderImages(urls)}
    </div>
  );
};

export default MolasContent;